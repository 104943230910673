.api-terminal__curl-form {
  height: 45vh;
  overflow: hidden; /* Ensure the lines stay within the panel */
  overflow-y: scroll; /* make panel C scrollable */
}

.api-terminal__curl-form pre {
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.section-right__terminal-container--api-terminal {
  margin-top: 1.6rem;
}
