.chatbot {
  padding: 0.75em;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
  gap: 0.75em;
}

.chatbot__prompt {
  height: 6.4rem;
  resize: none;
  padding: 0;
  width: 100%;
  border: none;
  border-radius: 0.8rem;
  padding: 0.8rem;
}

.chatbot__response {
  border-radius: 0.8rem;
  width: 100%;
  padding: 0.5em;
  word-wrap: break-word;
  overflow-y: auto;
  flex: 1;
}
