.update-user-admin-layout {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.update-user-admin-form-container {
  width: 100%;
  max-width: 32rem;
}

.update-user-admin-form__fields > * + * {
  margin-top: 2.4rem;
}

.update-user-admin-form__checkboxes > * + * {
  margin-top: 0.8rem;
}

.update-user-admin-form__buttons {
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;
}
