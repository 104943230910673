.rct-tree-item-button {
  white-space: nowrap;
}

.rct-tree-item-folder-icon {
  margin-right: 0.5em;
}

.rct-tree-item-title {
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
}

.rct-tree-item-more-options-button {
  visibility: hidden;
  opacity: 0;
}

.rct-tree-item-title-container:hover .rct-tree-item-more-options-button,
.menu.open .rct-tree-item-more-options-button {
  visibility: visible;
  opacity: 1;
}

.rct-tree-item-more-options-delete-button,
.rct-tree-item-more-options-delete-button:hover,
.rct-tree-item-more-options-delete-button svg {
  color: var(--btn-danger-txt-clr) !important;
}
