.graph-view {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */
  overflow-y: hidden;
}

.graph-view__toolbar-container {
  /* position: sticky;
  top: 0; */
  /* Prevents overlapping the toolbar menus */
  /* z-index: var(calc(--z-index-toolbar - 500)); */
  background-color: var(--main-content-bg-clr);
  border-bottom: 1px solid var(--main-content-border-clr);
}

.graph-view__toolbar-item-with-tooltip-container {
  flex: 1;
  display: flex;
  align-items: stretch;
}

.graph-view__diagrams-container {
  overflow-y: auto;
}

.grap-view__diagram {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.graph-view__diagram-title-container {
  padding: 0.25em 0.5em;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.graph-view__diagram-title {
  line-height: 100%;
}

.grap-view__diagram svg[name^="svg-container-graph-"] {
  width: 100% !important;
}

.graph-view__toolbar-placeholder {
  height: 4.08rem;
}

.graph-view__diagram-placeholder {
  height: 43.04rem;
}

svg[name="svg-container-graph-0"] {
  flex: 1;
}

.node text {
  font-size: 0.85em;
  font-weight: 700;
}

/* NODES */
/* If they implement an API */
.grap-view__diagram .node path[fill="blue"],
.grap-view__diagram .node path[fill="darkblue"] {
  fill: var(--dag-node-api-bg-clr) !important;
}
/* If they do NOT implement an API */
.grap-view__diagram .node path[fill="green"],
.grap-view__diagram .node path[fill="darkgreen"] {
  fill: var(--dag-node-default-bg-clr) !important;
}

/* BORDERS */
/* If they implement an API */
g.node path[stroke="red"][fill="blue"] {
  stroke: var(--dag-node-api-border-clr-hover) !important;
  stroke-width: 3px;
}
/* If the task is being executed */
g.node path[fill="#050"] {
  stroke: #050 !important;
  stroke-width: 3px;
}

/* If they do NOT implement an API */
g.node path[stroke="red"][fill="green"] {
  stroke: var(--dag-node-default-border-clr-hover) !important;
  stroke-width: 3px;
}

g.node path[stroke="orangered"] {
  stroke: var(--dag-node-compliance-border) !important;
  stroke-width: 2px;
}

/* Compliance tasks */
g.node path[stroke="red"][fill="darkblue"],
g.node path[stroke="red"][fill="darkgreen"] {
  stroke: var(--dag-node-compliance-border) !important;
  stroke-width: 3px;
}

/* LABELS */
/* Default Styles */
.grap-view__diagram .node text {
  fill: var(--dag-label-clr) !important;
}
/* Highlighted Styles */
/* If belongs to a node that implements an API */
g.node path[stroke="red"][fill="blue"] + text,
g.node path[stroke="red"][fill="darkblue"] + text {
  fill: var(--dag-label-api-clr-hover) !important;
}
/* If belongs to a node that do NOT implement an API */
g.node path[stroke="red"][fill="green"] + text,
g.node path[stroke="red"][fill="darkgreen"] + text {
  fill: var(--dag-label-default-clr-hover) !important;
}

/* LINKS */
/* Default Styles */
/* Arrow */
.grap-view__diagram #marker-small {
  fill: var(--dag-link-clr);
}
/* Link */
.grap-view__diagram path.link {
  stroke: var(--dag-link-clr) !important;
}

/* Highlighted Styles */
/* Arrow */
#marker-small-highlighted {
  fill: var(--dag-link-clr-hover);
}
/* Link */
.grap-view__diagram path.link[marker-end="url(#marker-small-highlighted)"] {
  stroke: var(--dag-link-clr-hover) !important;
}
