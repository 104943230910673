.embeddings-manager__section {
  margin-top: 2.4rem;
}

.embeddings-manager__section-title {
  margin: 0.5em 0;
}

.embeddings-manager__table-container {
  max-height: 40rem; /* Set the height as per your need */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid var(--table-border-clr);
  border-radius: 0.5em;
}

.embeddings-manager__table {
  width: 100%;
  table-layout: fixed; /* Ensures fixed layout */
}

.embeddings-manager__table th,
.embeddings-manager__table td {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.embeddings-manager__import-button {
  padding: 1.2rem 1.6rem !important;
}

.text-column {
  max-width: 300px; /* Adjust this value as needed */
  white-space: nowrap;
  overflow: hidden;
}

.text-column:hover {
  white-space: normal;
  overflow: visible;
  position: relative;
  z-index: 1;
}

.actions-column {
  display: flex;
}

.export-buttons-container {
  display: flex;
  gap: 10px;
  width: 100%;
  align-self: flex-start;
}

.blinking {
  margin-left: 10px;
  animation: blinkingText 1.2s infinite;
}

@keyframes blinkingText {
  0% {
    color: var(--app-txt-clr);
  }
  49% {
    color: var(--app-txt-clr);
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: var(--app-txt-clr);
  }
}
