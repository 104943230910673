.tree-menu__item-button-group {
  display: flex;
  gap: 0.25em;
  margin-left: auto;
}

.tree-menu__heading {
  font-weight: 700;
}

/* Scrollable Workspaces submenu */
.menu__items.workspaces-submenu {
  max-height: 100px !important; /* Ensure it overrides default styles */
  overflow-y: auto !important; /* Enable vertical scrolling */
}

/* Custom scrollbar styles */
.menu__items.workspaces-submenu::-webkit-scrollbar {
  width: 6px !important; /* Scrollbar width */
}

.menu__items.workspaces-submenu::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2) !important; /* Scrollbar thumb color */
  border-radius: 3px !important; /* Rounded corners */
}

.menu__items.workspaces-submenu::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4) !important; /* Darker color on hover */
}

.menu__items.workspaces-submenu::-webkit-scrollbar-track {
  background: transparent !important; /* Scrollbar track background */
}

.tree-menu__nested-menu--scrollable {
  overflow-x: clip;
}

.tree-menu__nested-menu--scrollable > li > .menu__item {
  padding-right: 2rem;
  white-space: nowrap;
}
