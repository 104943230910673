.apis-editor {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.apis-editor__header {
  position: sticky;
  top: 0;
  z-index: var(--z-index-topbar);
  border-bottom: 1px solid var(--navbar-border-clr);
}

/* Horizontally split layout */
/* Takes up all available vertical space */
.apis-editor__main {
  flex: 1;
  overflow-y: hidden;
  display: flex;
}

.apis-editor__sidebar {
  background-color: var(--panel-bg-clr);
  width: 43rem;
  overflow-y: scroll;
}

.apis-editor__section {
  height: 100%;
}

.apis-editor__content-wrapper {
  /* Takes up all available horizontal space */
  background-color: var(--main-content-bg-clr);
  flex: 1;
  height: 100%;
}

.apis-editor__section--shortcuts {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  padding: 1rem;
}

.apis-editor__section--details {
  overflow-y: scroll;
}
