.skeletons {
  background-color: var(--panel-bg-clr);
}
.skeletons__toolbar {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  padding: 0.4rem;
  border-bottom: 1px solid var(--panel-border-clr);
}

.skeletons__tree {
  padding: 0.4rem 0;
}

.skeletons__tree > * + * {
  margin-top: 0.2rem;
}
