.my-folder-tree {
  --rct-color-tree-bg: var(--tree-bg-clr);
  --rct-item-height: 3.4rem;
  --rct-color-search-highlight-bg: var(--tree-search-bg-clr);

  --rct-color-tree-focus-outline: var(--tree-item-outline-clr);
  --rct-item-margin: 1px;
  --rct-item-padding: 0.8rem;
  --rct-radius: 0.4rem;
  --rct-bar-offset: 0.6rem;
  --rct-bar-width: 0.4rem;
  --rct-bar-color: var(--tree-bar-clr);
  --rct-focus-outline: transparent;

  --rct-color-focustree-item-selected-bg: var(--tree-item-bg-clr-hover);
  --rct-color-focustree-item-selected-text: var(--tree-item-txt-hover);
  --rct-color-focustree-item-hover-bg: var(--tree-item-bg-clr-hover);
  --rct-color-focustree-item-hover-text: var(--tree-item-txt-hover);
  --rct-color-focustree-item-active-bg: var(--tree-item-bg-clr-hover);
  --rct-color-focustree-item-active-text: var(--tree-item-txt-hover);

  --rct-arrow-size: 1.4rem;
  --rct-arrow-container-size: 2rem;
  --rct-arrow-padding: 0.6rem;
  --rct-color-arrow: var(--tree-bar-clr);

  --rct-cursor: pointer;

  --rct-search-width: 12rem;
  --rct-search-height: 2.4rem;
  --rct-search-padding: 0.8rem;
  --rct-search-border: var(--tree-search-border-clr);
  --rct-search-border-bottom: var(--tree-search-border-clr);
  --rct-search-bg: var(--tree-search-bg-clr);
  --rct-search-text: var(--tree-search-txt-clr);
  --rct-search-text-offset: calc(var(--rct-search-padding) * 2 + 1.6rem);

  --rct-color-renaming-input-submitbutton-bg: var(--tree-submit-button-bg-clr);
  --rct-color-renaming-input-submitbutton-bg-hover: var(--tree-submit-button-bg-clr-hover);
  --rct-color-renaming-input-submitbutton-bg-active: var(--tree-submit-button-bg-clr-hover);
  --rct-color-renaming-input-submitbutton-width: 3rem;

  --rct-color-renaming-input-submitbutton-text: var(--tree-submit-button-txt-clr);
  --rct-color-renaming-input-submitbutton-text-active: var(--tree-submit-button-txt-clr-hover);
  --rct-color-renaming-input-submitbutton-text-hover: var(--tree-submit-button-txt-clr-hover);

  --rct-color-drag-between-line-bg: var(--tree-item-outline-clr);

  font-size: 1.6rem;
}

.my-folder-tree {
  background-color: var(--panel-bg-clr);
}

.my-folder-tree__toolbar-container {
  top: 0;
  position: sticky;
  z-index: var(--z-index-toolbar);
  background-color: var(--panel-bg-clr);
  border-bottom: 1px solid var(--panel-border-clr);
}

.rct-tree-root-focus {
  outline: none;
}
