.webhooks__form-input-client {
  display: none;
}

.webhooks__table-container {
  display: flex;
  justify-content: left; /* Align items horizontally in the center */
  align-items: normal; /* Align items vertically in the center */
  gap: 0.25em;
}

.webhooks__table__icon--help {
  align-self: flex-start;
  position: relative;
  top: -0.35rem;
}

.webhooks__table__row--selected {
  background-color: var(--table-td-bg-clr-hover);
}

.divider {
  margin: 2rem 0; /* Space above and below the divider */
  border-bottom: none;
}

.webhooks__main-client-th {
  min-width: max-content;
}

.webhooks__url-td {
  word-break: break-all;
}
