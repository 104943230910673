.export-guides__divider {
  border: none;
  border-bottom: 1px solid var(--card-border-clr);
}

.export-guides__tree-view {
  overflow-x: clip;
}

.export-guides__nodes {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.export-guides__nodes--root {
  gap: 0.25em;
}

.export-guides__nodes--children {
  padding-left: 3.1rem;
}

.export-guides__nodes--children .export-guides__nodes--children {
  padding-left: 6.2rem;
}

.export-guides__node {
  display: flex;
  align-items: center;
  gap: 0.25em;
}

.export-guides__node .checkbox-container {
  flex: 1;
  overflow: hidden;
}

.export-guides__node .checkbox__label {
  white-space: nowrap;
  overflow: hidden;
}

.export-guides__node-label {
  overflow: hidden;
  text-overflow: ellipsis;
}

.export-guides__button-icon {
  transform: rotate(180deg);
  transition: transform 150ms ease-in-out !important;
}

.export-guides__button-icon--expanded {
  transform: rotate(270deg);
}
