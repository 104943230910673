.third-parties__form-input-client {
  display: none;
}

.third-parties__table-container {
  display: flex;
  justify-content: left; /* Align items horizontally in the center */
  align-items: normal; /* Align items vertically in the center */
  gap: 0.25em;
}

.table__icon--help {
  align-self: flex-start;
  position: relative;
  top: -0.35rem;
}

.table__row--selected {
  background-color: var(--table-td-bg-clr-hover);
}
