.shortcuts__title {
  text-align: center;
}

.shortcuts__list {
  margin-top: 3rem;
}

.shortcuts__list > .shortcut + .shortcut {
  margin-top: 1.5rem;
}
.shortcut {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.shortcut__description {
  font-size: 1.15em;
  justify-self: start;
}

.shortcut__key {
  background-color: var(--clr-border-low-contrast);
  color: var(--btn-secondary-txt-clr-hover);
  border: 1px solid var(--btn-secondary-bg-clr-hover);
  border-radius: 0.2em;
  padding: 0.5em;
  margin-left: auto;
}
