.fileInput {
  display: none;
  height: 3.2rem;
  margin-top: 2.5rem;
  margin-right: 0;
  border-radius: 1rem;
  padding-top: 1rem;
  padding-left: 1rem;
  font-size: 1.8rem;
}

.uploadBox {
  padding: 0.5rem;
  background-color: transparent;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.uploadelements {
  display: flex;
  flex-direction: column;
  justify-content: left;
  border-radius: 2px;
}

.my-custom-file-label {
  display: block;
  color: var(--app-txt-clr);
  cursor: pointer;
  margin: 0.5rem;
  text-decoration: underline;
}

.confirm-upload__body {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.file-upload__progress-bar {
  margin-top: 2.4rem;
}
