.explorer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.explorer__header {
  position: sticky;
  top: 0;
  z-index: var(--z-index-topbar);
  border-bottom: 1px solid var(--navbar-border-clr);
}

.explorer__main {
  flex: 1;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}
