.api__details {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.api__details > div[id^="simple-tabpanel-"] {
  flex: 1;
}

.api__details > div > .tabpanel-container {
  height: 100%;
  padding: 1.5rem;
}

.api-details__header {
  display: flex;
  align-items: center;
  margin-top: 1em;
}

.api-details__header .api-details__button-delete-header {
  font-size: 2rem;
}

.api-details__button-delete-header svg {
  font-size: inherit;
}

.api-details__save-button-container {
  justify-content: flex-start;
}
