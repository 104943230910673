:root {
  color-scheme: var(--app-clr-scheme);
  --color-scheme: var(--app-clr-scheme);
  --transition-duration-fast: 120ms;
  --transition-duration-normal: 250ms;
  --transition-duration-slow: 400ms;
  --z-index-toolbar: 1000;
  --z-index-topbar: 2000;
  --z-index-menu: 3000;
  --z-index-modal: 4000;
  --z-index-tooltip: 5000;
  --z-index-widget: 6000;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  font-family: var(--app-ff--primary), monospace;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* Makes 1rem = 10px */
/* 62.5*16px(base) = 10px */
html {
  font-size: 62.5%;
}

/* LAYOUT */
html,
#root,
body {
  /* overflow-y: hidden; */
  height: 100%;
}

body {
  background-color: var(--card-surface-around-bg-clr);
  color: var(--clr-txt-body);
  font-size: 1.6rem;
}

a {
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  cursor: pointer;
  display: block;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
p a {
  display: inline;
}

.rct-tree-item-li,
.MuiButtonBase-root {
  font-size: inherit !important;
}

/* Tabs text color */
button.MuiTab-textColorPrimary,
button.MuiButton-text.MuiButton-textPrimary {
  color: var(--tab-txt-clr);
  background-color: var(--tab-bg-clr);
  border-bottom: 2px solid var(--tab-border);
  transition: color var(--transition-duration-normal) ease-in-out;
}

/* Tabs text color on hover */
button.Mui-selected.MuiTab-textColorPrimary,
button.MuiTab-textColorPrimary:hover,
button.MuiButton-textPrimary:hover {
  color: var(--tab-txt-clr-hover);
  background-color: var(--tab-bg-clr-hover);
  border-color: var(--tab-border-hover);
}

/* Tabs indicator color */
span.MuiTabs-indicator {
  background-color: var(--tab-border-selected);
}

/* Support widget */
#jsd-widget {
  /* Makes the widget background transparent */
  color-scheme: light;
}

/* TERMINAL MESSAGES */
.terminal__message {
  color: var(--terminal-txt-clr-info);
}

.terminal__message.terminal__message--error {
  color: var(--terminal-txt-clr-error);
}

.terminal__code {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-all;
}

/* TABLE */
table {
  border-collapse: collapse;
  overflow: hidden;
}

thead {
  background-color: var(--table-th-bg-clr);
  border-bottom: 1px solid var(--table-border-clr);
  color: var(--table-th-txt-clr);
  font-weight: 700;
}

thead:hover {
  background-color: var(--table-th-bg-clr-hover);
  color: var(--table-th-txt-clr-hover);
}

tbody {
  background-color: var(--table-td-bg-clr);
  color: var(--table-td-txt-clr);
}

th,
td {
  text-align: left;
  padding: 0.8rem;
}

tbody tr:hover {
  cursor: pointer;
  background-color: var(--table-td-bg-clr-hover);
  color: var(--table-td-txt-clr-hover);
}

/* UTILITY CLASSES */
.visually-hidden {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}
