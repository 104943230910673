.navbar {
  display: flex;
  padding: 0 1.6rem;
  background-color: var(--navbar-bg-clr);
  color: var(--navbar-txt-clr);
}

.navbar__brand {
  font-size: 2.4rem;
  color: var(--navbar-logo-txt-clr);
  display: flex;
  align-items: center;
  padding: 0.25em;
}

.navbar__brand-logo {
  border-radius: 0.25em;
  outline-offset: -4px;
  color: var(--navbar-logo-txt-clr);
  padding: 0.4em 0.75em;
  transition:
    background-color var(--transition-duration-normal, 250ms) ease-in-out,
    outline var(--transition-duration-normal, 250ms) ease-in-out;
}

.navbar__brand:focus-visible {
  outline: none;
}

.navbar__brand:focus-visible .navbar__brand-logo {
  background-color: var(--navbar-bg-clr-hover);
  outline: 4px solid var(--navbar-outline-clr);
}

.navbar__brand-explorer-id {
  font-size: 2rem;
}

.navbar__items {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  list-style: none;
  gap: 0.25em;
}

.navbar__item {
  display: flex;
  align-items: center;
}

.navbar__item--profile {
  padding-left: 2rem;
}

.navbar__item-button {
  border: none;
  font-size: inherit;
  border-radius: 0.35em;
  padding: 0.75em 1em;
  background-color: var(--navbar-bg-clr);
  color: var(--navbar-txt-clr);
  outline-offset: -2px;
  transition:
    background-color var(--transition-duration-normal, 250ms) ease-in-out,
    color var(--transition-duration-normal, 250ms) ease-in-out,
    outline var(--transition-duration-normal, 250ms) ease-in-out;
  cursor: pointer;
}

.menu.open .navbar__item-button,
.navbar__item-button:hover,
.navbar__item-button:focus-visible {
  background-color: var(--navbar-bg-clr-hover);
  color: var(--navbar-txt-clr-hover);
}

.navbar__item--profile .navbar__item-button {
  background-color: var(--navbar-cta-bg-clr);
  color: var(--navbar-cta-txt-clr);
}

.navbar__item--profile .navbar__item-button:hover,
.navbar__item--profile .navbar__item-button:focus-visible {
  background-color: var(--navbar-cta-bg-clr-hover);
  color: var(--navbar-cta-txt-clr-hover);
}

.navbar__item--profile .navbar__item-text {
  cursor: default;
}

.navbar__item-button:focus-visible {
  outline: 4px solid var(--navbar-outline-clr);
}

.navbar__item--profile .navbar__item-button:focus-visible {
  outline: 4px solid var(--navbar-cta-outline-clr);
}
