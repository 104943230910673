.product-tree {
  display: flex;
  overflow-y: hidden;
  flex: 1;
}

.product-tree__section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.product-tree__section--left {
  /* overflow-y: auto; */
  overflow-y: hidden;
  max-width: 30rem;
  background-color: var(--panel-bg-clr);
}

.product-tree__section--left .product-tree__section-toolbar-container,
.section-right__toolbar-container {
  /* position: sticky;
  top: 0; */
  position: relative;
  z-index: var(--z-index-toolbar);
  background-color: var(--panel-bg-clr);
  border-bottom: 1px solid var(--panel-border-clr);
}

/* Prevents overlapping with menu handler */
.section-right__toolbar-container {
  padding-right: 4.8rem;
}

.product-tree__menu--left-container {
  position: fixed;
}

.product-tree__section-container {
  flex: 1;
  padding: 1.6rem;
  overflow-y: auto;
}

.product-tree__section--middle {
  width: 100%;
  /* max-width: 65rem; */
  overflow-x: hidden;
  /* overflow-y: auto; */
  background-color: var(--main-content-bg-clr);
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.section-middle__top {
  display: flex;
  flex-direction: column;
  /* min-height: fit-content; */
  height: 47.12rem;
  overflow-y: hidden;
}

.section-middle__bottom {
  flex: 1;
  min-height: 15.5rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.product-tree__section--right {
  /* overflow-y: auto; */
  overflow-y: hidden;
  background-color: var(--panel-bg-clr);
}

.section-right__menu {
  position: fixed;
  right: 1em;
  transform: translate(-50%, 0.25em);
  z-index: var(--z-index-menu);
}

.section-right__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  overflow-y: auto;
}

.section-right__form {
  flex: 1;
}

.section-right__terminal-container {
  flex: 1;
}

.section-right__syntax-highlighter {
  width: 100%;
  flex: 1;
}

.section-right__toolbar-container--empty {
  min-height: 4.08rem;
  border-bottom: 1px solid var(--panel-border-clr);
}

/* MIDDLE */
.tree-node {
  cursor: pointer;
}

/* Apply indentation for child nodes */
.child-node {
  margin-left: 4rem;
}

.workflow {
  margin-left: 3rem;
  cursor: pointer;
}
