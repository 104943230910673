.fetch-error {
  text-align: center;
}

.fetch-error__message {
  color: var(--terminal-txt-clr-error);
}

.fetch-error__button {
  margin: auto;
  margin-top: 1rem;
  width: 100%;
  max-width: max-content;
}
